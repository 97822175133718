import './App.css';
import ChatScreen from './Components/ChatScreen'
let USER_ID="1"
let NICKNAME="Nate Test"
let plainProfileUrl="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg"
function App() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if(params && params.userId){
    USER_ID=params.userId
  }
  if(params && params.userName){
    NICKNAME=params.userName
  }
  if(params && params.plainProfileUrl){
    plainProfileUrl=params.plainProfileUrl
  }
  var element = document.getElementById('speaky_chat_root');
  const uId=element.getAttribute('data-user_id');
  const fullname=element.getAttribute('data-fullname');
  const profile_img=element.getAttribute('data-profile_img');
  if(element && uId){
    USER_ID=uId;
  }
  if(element && fullname){
    NICKNAME=fullname;
  }

  if(element && profile_img){
    plainProfileUrl=profile_img;
  }
  
  console.log("speaky_data",USER_ID);
  return (
    <div className="App">
        <div className='wrapper-class'>
            <ChatScreen USER_ID={USER_ID} NICKNAME={NICKNAME} plainProfileUrl={plainProfileUrl} />
        </div>
    </div>
  );
}

export default App;
