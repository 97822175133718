
import { useState, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import SendbirdChat from '@sendbird/chat';
import {
    GroupChannelModule,
    GroupChannelFilter,
    GroupChannelListOrder,
    MessageFilter,
    MessageCollectionInitPolicy
} from '@sendbird/chat/groupChannel';
import { timestampToTime, handleEnterPress,WordLimitComponent } from '../utils/messageUtils';
let sb;
const APP_ID="DA774053-8C31-4B91-800C-B62555B791BC";

const ChatScreen = (props) => {
    const {USER_ID,NICKNAME,plainProfileUrl}=props;
    
    const [state, updateState] = useState({
        applicationUsers: [],
        groupChannelMembers: [],
        currentlyJoinedChannel: null,
        messages: [],
        channels: [],
        members: [],
        sortMembers: 'all',
        messageInputValue: "",
        userNameInputValue: "",
        userIdInputValue: "",
        channelNameUpdateValue: "",
        settingUpUser: true,
        file: null,
        messageToUpdate: null,
        messageCollection: null,
        loading: false,
        error: false,
        userIsOperator: false,
        isActiveMobile:false,
    });

    //need to access state in message received callback
    const stateRef = useRef();
    stateRef.current = state;

    const channelRef = useRef();
    const chatContainerRef = useRef(null);

    const channelHandlers = {
        onChannelsAdded: (context, channels) => {
            const updatedChannels = [...channels, ...stateRef.current.channels];
            updateState({ ...stateRef.current, channels: updatedChannels, applicationUsers: [] });
        },
        onChannelsDeleted: (context, channels) => {
            const updatedChannels = stateRef.current.channels.filter((channel) => {
                return !channels.includes(channel.url);
            });
            updateState({ ...stateRef.current, channels: updatedChannels });

        },
        onChannelsUpdated: (context, channels) => {
            const updatedChannels = stateRef.current.channels.map((channel) => {
                const updatedChannel = channels.find(incomingChannel => incomingChannel.url === channel.url);
                if (updatedChannel) {
                    return updatedChannel;
                } else {
                    return channel;
                }
            });

            updateState({ ...stateRef.current, channels: updatedChannels });
        },
    }

    const messageHandlers = {
        onMessagesAdded: (context, channel, messages) => {
            const updatedMessages = [...stateRef.current.messages, ...messages];

            updateState({ ...stateRef.current, messages: updatedMessages });

        },
        onMessagesUpdated: (context, channel, messages) => {
            const updatedMessages = [...stateRef.current.messages];
            for (let i in messages) {
                const incomingMessage = messages[i];
                const indexOfExisting = stateRef.current.messages.findIndex(message => {
                    return incomingMessage.reqId === message.reqId;
                });

                if (indexOfExisting !== -1) {
                    updatedMessages[indexOfExisting] = incomingMessage;
                }
                if (!incomingMessage.reqId) {
                    updatedMessages.push(incomingMessage);
                }
            }


            updateState({ ...stateRef.current, messages: updatedMessages });
        },
        onMessagesDeleted: (context, channel, messageIds) => {
            const updateMessages = stateRef.current.messages.filter((message) => {
                return !messageIds.includes(message.messageId);
            });
            updateState({ ...stateRef.current, messages: updateMessages });

        },
        onChannelUpdated: (context, channel) => {

        },
        onChannelDeleted: (context, channelUrl) => {
        },
        onHugeGapDetected: () => {
        }
    }

    const scrollToBottom = (item, smooth) => {
        item?.scrollTo({
            top: item.scrollHeight,
            behavior: smooth
        })
    }

    useEffect(() => {
        scrollToBottom(channelRef.current)
    }, [state.currentlyJoinedChannel])

    useEffect(() => {
        scrollToBottom(channelRef.current, 'smooth')
    }, [state.messages])

    const onError = (error) => {
        updateState({ ...state, error: error.message });
        console.log(error);
    }

    const handleJoinChannel = async (channelUrl) => {
        // console.log("channelUrl",channelUrl);
        if (state.messageCollection && state.messageCollection.dispose) {
            state.messageCollection?.dispose();
        }
        updateState({ ...state, isActiveMobile: true });  
        if (state.currentlyJoinedChannel?.url === channelUrl) {
            return null;
        }
        
        const { channels, userIdInputValue } = state;
        updateState({ ...state, loading: true });
        const channel = channels.find((channel) => channel.url === channelUrl);


        const memberIndex = channel.members.findIndex((item => item.userId === userIdInputValue));
        const userIsOperator ="operator"


        const onCacheResult = (err, messages) => {
            updateState({ ...stateRef.current, currentlyJoinedChannel: channel, messages: messages.reverse(), loading: false })

        }

        const onApiResult = (err, messages) => {
            updateState({ ...stateRef.current, currentlyJoinedChannel: channel, messages: messages.reverse(), loading: false })
        }

        const collection = loadMessages(channel, messageHandlers, onCacheResult, onApiResult);
        updateState({ ...state, loading: false, members: channel.members, userIsOperator: userIsOperator })
    }

    const backOnClick=()=>{
        updateState({ ...state, isActiveMobile: false });
    }

    const handleLeaveChannel = async () => {
        const { currentlyJoinedChannel } = state;
        await currentlyJoinedChannel.leave();

        updateState({ ...state, currentlyJoinedChannel: null })
    }

    const handleCreateChannel = async (channelName = "testChannel",) => {
        const [groupChannel, error] = await createChannel(channelName, state.groupChannelMembers);
        if (error) {
            return onError(error);
        }


    }

    const handleUpdateChannelMembersList = async () => {
        const { currentlyJoinedChannel, groupChannelMembers } = state;
        await inviteUsersToChannel(currentlyJoinedChannel, groupChannelMembers);
        updateState({ ...state, applicationUsers: [] });
    }

    const handleDeleteChannel = async (channelUrl) => {
        const [channel, error] = await deleteChannel(channelUrl);
        if (error) {
            return onError(error);
        }

    }

    const handleMemberInvite = async () => {
        const [users, error] = await getAllApplicationUsers();
        if (error) {
            return onError(error);
        }
        updateState({ ...state, applicationUsers: users });
    }

    const onUserNameInputChange = (e) => {
        const userNameInputValue = e.currentTarget.value;
        updateState({ ...state, userNameInputValue });
    }

    const onUserIdInputChange = (e) => {
        const userIdInputValue = e.currentTarget.value;
        updateState({ ...state, userIdInputValue });
    }

    const onMessageInputChange = (e) => {
        const messageInputValue = e.currentTarget.value;
        updateState({ ...state, messageInputValue });
    }

    const sendMessage = async () => {
        const { messageToUpdate, currentlyJoinedChannel, messages } = state;
        if (messageToUpdate) {
            const userMessageUpdateParams = {};
            userMessageUpdateParams.message = state.messageInputValue;
            const updatedMessage = await currentlyJoinedChannel.updateUserMessage(messageToUpdate.messageId, userMessageUpdateParams)
            const messageIndex = messages.findIndex((item => item.messageId == messageToUpdate.messageId));
            messages[messageIndex] = updatedMessage;
            updateState({ ...state, messages: messages, messageInputValue: "", messageToUpdate: null });
        } else {
            const userMessageParams = {};
            userMessageParams.message = state.messageInputValue;
            currentlyJoinedChannel.sendUserMessage(userMessageParams)
                .onSucceeded((message) => {
                    updateState({ ...stateRef.current, messageInputValue: "" });

                })
                .onFailed((error) => {
                    console.log(error)
                    console.log("failed")
                });
        }
    }


      // Scroll to the last li element whenever messages change
  useEffect(() => {
    if (chatContainerRef.current) {
      const lastMessage = chatContainerRef.current?.lastChild;
      if(lastMessage){
        lastMessage.scrollIntoView({ behavior: 'smooth' });
      }
     
    }
  }, [state.messages]);

    const onFileInputChange = async (e) => {
        if (e.currentTarget.files && e.currentTarget.files.length > 0) {
            const { currentlyJoinedChannel, messages } = state;
            const fileMessageParams = {};
            fileMessageParams.file = e.currentTarget.files[0];
            currentlyJoinedChannel.sendFileMessage(fileMessageParams)
                .onSucceeded((message) => {
                    updateState({ ...stateRef.current, messageInputValue: "", file: null });

                })
                .onFailed((error) => {
                    console.log(error)
                    console.log("failed")
                });
        }
    }

    const handleDeleteMessage = async (messageToDelete) => {
        const { currentlyJoinedChannel } = state;
        await deleteMessage(currentlyJoinedChannel, messageToDelete); // Delete
    }

    const updateMessage = async (message) => {
        updateState({ ...state, messageToUpdate: message, messageInputValue: message.message });
    }

    const handleLoadMemberSelectionList = async () => {
        updateState({ ...state, currentlyJoinedChannel: null });
        const [users, error] = await getAllApplicationUsers();
        if (error) {
            return onError(error);
        }
        updateState({ ...state, currentlyJoinedChannel: null, applicationUsers: users, groupChannelMembers: [sb.currentUser.userId] });
    }

    const addToChannelMembersList = (userId) => {
        const groupChannelMembers = [...state.groupChannelMembers, userId];
        updateState({ ...state, groupChannelMembers: groupChannelMembers });
    }

   


    useEffect(() => {
        const setupUser = async () => {
        const sendbirdChat = await SendbirdChat.init({
            appId: APP_ID,
            localCacheEnabled: true,
            modules: [new GroupChannelModule()]
        });

        await sendbirdChat.connect(USER_ID);
        await sendbirdChat.setChannelInvitationPreference(true);

        const userUpdateParams = {};
        userUpdateParams.nickname = NICKNAME;
        userUpdateParams.userId = USER_ID;
        userUpdateParams.profileUrl=plainProfileUrl;
        await sendbirdChat.updateCurrentUserInfo(userUpdateParams);

        sb = sendbirdChat;
        updateState({ ...state, loading: true });
        const [channels, error] = await loadChannels(channelHandlers);
        if (error) {
            return onError(error);
        }

        updateState({ ...state, channels: channels, loading: false, settingUpUser: false });
    }
         setupUser();
     }, [USER_ID,NICKNAME,plainProfileUrl]);

    const handleOperator = async (callbackName, member) => {
        const { currentlyJoinedChannel, members } = state;

        try {
            await currentlyJoinedChannel[callbackName]([member.userId]);
            updateState({ ...state, members: members })
        } catch (error) {
            console.log("Error");
            console.log(error);
        }
    }

    const handleSortMembers = async (event) => {
        if (event.target.value !== 'all') {
            const [updatedMembers, error] = await loadMembers(state.currentlyJoinedChannel.url, event.target.value)
            if (error) {
                return onError(error);
            }
            updateState({ ...state, members: updatedMembers, sortMembers: event.target.value });
        } else {
            updateState({ ...state, sortMembers: event.target.value });
        }
    }

    const registerUnregisterAnOperator = (member) => {
        if (member.role === "operator") {
            handleOperator("removeOperators", member);
            alert("Operator was unregister");
        } else {
            handleOperator("addOperators", member);
            alert("Operator was register");
        }
    }

    if (state.loading) {
        return <div id="loader" className="loader-container" style={{display:'block'}}><div className="loader"></div></div>
    }

    if (state.error) {
        return <div className="error">{state.error} check console for more information.</div>
    }

    // console.log('- - - - State object very useful for debugging - - - -');
    // console.log(state);

    return (
        <>
        <div className={state.isActiveMobile ? "chat-wrap active" : "chat-wrap"}>
        {state.channels.length===0 ? <p>The chat will open once you have completed your first call.</p>:
            <>
            <div className="chat-left">
                <ChannelList
                channels={state.channels}
                handleJoinChannel={handleJoinChannel}
                handleCreateChannel={handleLoadMemberSelectionList}
                handleDeleteChannel={handleDeleteChannel}
                handleLoadMemberSelectionList={handleLoadMemberSelectionList}
                USER_ID={USER_ID} />
              </div>
              
              <div className="chat-right">
                    <Channel
                        currentlyJoinedChannel={state.currentlyJoinedChannel}
                        handleLeaveChannel={handleLeaveChannel}
                        channelRef={channelRef}
                        backOnClick={backOnClick}
                        USER_ID={USER_ID}
                    >
                        <MessagesList
                            messages={state.messages}
                            handleDeleteMessage={handleDeleteMessage}
                            updateMessage={updateMessage}
                            chatContainerRef={chatContainerRef}
                        />
                        <MessageInput
                            value={state.messageInputValue}
                            onChange={onMessageInputChange}
                            sendMessage={sendMessage}
                            fileSelected={state.file}
                            onFileInputChange={onFileInputChange}
                        />
                    </Channel>
                    </div> </>}

                    <div id="loader" className="loader-container" style={{display:(state.loading)?'block':'none'}}><div className="loader"></div></div>
            </div>
        </>
    );
};

// Chat UI Components
const ChannelList = ({
    channels,
    handleJoinChannel,
    handleDeleteChannel,
    handleLoadMemberSelectionList,
    USER_ID
}) => {
    return (
        <div className='channel-list'>
            <ul>
            {channels.map(channel => {
                 if(channel?.memberCount>1){
                        return (
                            <li key={channel.url} className="channel-list-item" >
                                <div
                                    className="channel-list-item-name"
                                    onClick={() => { handleJoinChannel(channel.url) }}>
                                    <ChannelName members={channel.members} USER_ID={USER_ID} />
                                    <div className="last-message">{WordLimitComponent(channel.lastMessage?.message)}</div>
                                </div>
                            </li>
                        );
                 }
            })}
            </ul>
        </div>
    );
}

const ChannelName = ({ members,USER_ID }) => {
    if(members && members.length>1){
        const result = members.find(item => item.userId !== USER_ID);
        const {userId,nickname,plainProfileUrl,connectionStatus}=result;
        return (<>
        {plainProfileUrl && <img src={plainProfileUrl} alt="avatar" />}
        {(!plainProfileUrl || plainProfileUrl==='') && <div className="profile-image-fallback">{nickname.charAt(0)}</div>}
       
				<div className="user-info" key={userId}>
                    {nickname && <h6>{nickname}</h6> }
					{/* {(connectionStatus && connectionStatus==="online")? <span className="status online">online</span>:<span className="status offline">offline</span>} */}
					
				</div>
        </>);
    }
}

const Channel = ({ currentlyJoinedChannel, children, handleLeaveChannel, channelRef,USER_ID,backOnClick }) => {
    if (currentlyJoinedChannel) {
        return <div className="channel" ref={channelRef}>
            <ChannelHeader selectedUser={currentlyJoinedChannel.members} USER_ID={USER_ID} backOnClick={backOnClick}/>
            <div>
                <button className="leave-channel" onClick={handleLeaveChannel}>Leave Channel</button>
            </div>
            <div>{children}</div>
        </div>;
    }
    return <div className="channel"></div>;
}

const ChannelHeader = ({ selectedUser,USER_ID,backOnClick }) => {
    if(selectedUser && selectedUser.length>1){
        
            const result = selectedUser.find(item => item.userId !== USER_ID);
            const {nickname,plainProfileUrl,connectionStatus}=result;
        return <div className="chat-header">
        <button type="button"  onClick={backOnClick} className="back-btn">
          Back
        </button>
        {plainProfileUrl && <img src={plainProfileUrl} alt="avatar" />}
        {(!plainProfileUrl || plainProfileUrl==='') && <div className="profile-image-fallback">{nickname.charAt(0)}</div>}
        <div className="chat-about">
          <h4>Chat with {nickname}</h4>
          {/* {(connectionStatus && connectionStatus==="online")? <span className="status online">online</span>:<span className="status offline">offline</span>} */}
        </div>
      </div>;
    }else{
        return false;
    }
    
}
const MembersList = ({ channel, members, sortMembers, handleMemberInvite, registerUnregisterAnOperator, userIsOperator, userIdInputValue, handleSortMembers }) => {
    if (members) {
        return <div className="members-list">
            <button onClick={handleMemberInvite}>Invite</button>
            <div>
                <select onChange={(event) => handleSortMembers(event)}>
                    <option value="all">All members</option>
                    <option value="member_nickname_alphabetical">Alphabetical order</option>
                    <option value="operator_then_member_alphabetical">Operators first, alphabetical order</option>
                </select>
            </div>
            {(sortMembers !== 'all' ? members : channel?.members)
                ?.map((member) => {
                    const isOperator = (member.role === "operator");
                    const memberIsSender = (member.userId !== userIdInputValue);
                    return (
                        <div key={member.userId}>
                            {userIsOperator && <div key={member.userId} className="member-item-wrapper">
                                <div className="member-item">
                                    {member.nickname}
                                    {isOperator && <img className="message-icon" src='/operator_icon.png' />}
                                </div>
                                {memberIsSender && <button onClick={() => registerUnregisterAnOperator(member)}>
                                    {isOperator ? "Unregister as operator" : "Register as operator"}
                                </button>}
                            </div>}
                            {!userIsOperator && <div className="member-item-wrapper">
                                <div className="member-item">
                                    {member.nickname}
                                    {isOperator && <img className="message-icon" src='/operator_icon.png' />}
                                </div>
                            </div>}
                        </div>
                    )
                })
            }
        </div>;
    } else {
        return null;
    }
}




const MessagesList = ({ messages, handleDeleteMessage, updateMessage , chatContainerRef}) => {
    return <div className="message-list chat-box">
        <ul ref={chatContainerRef}>
        {messages.map(message => {
            if (!message.sender) return null;
            const messageSentByYou = message.sender.userId === sb.currentUser.userId;
            return (
                <li key={message.messageId}>
                    <div className={`message-item message-data ${messageSentByYou ? 'message-from-you me' : ''}`}> 
                    <span className="time">{timestampToTime(message.createdAt)}</span>
	                 <span className={`name ${message.sender.connectionStatus}`}>{message.sender.nickname}{' '}</span>
                    </div>
                    <Message
                        message={message}
                        handleDeleteMessage={handleDeleteMessage}
                        updateMessage={updateMessage}
                        messageSentByYou={messageSentByYou} />
                  
                </li>
            );
        })}
        </ul>
    </div>
}

const Message = ({ message, updateMessage, handleDeleteMessage, messageSentByYou }) => {
    if (message.url) {
        return (
            <div className={`message  ${messageSentByYou ? 'message-from-you' : ''}`}>
                <div className="message-user-info">
                    <div className="message-sender-name">{message.sender.nickname}{' '}</div>
                    <div>{timestampToTime(message.createdAt)}</div>
                </div>
                <img src={message.url} />
            </div >);
    }
    const messageSentByCurrentUser = message.sender.userId === sb.currentUser.userId;

    return (
        <div className={`message  ${messageSentByYou ? 'message-from-you' : ''}`}>
            <div>{message.message}</div>
        </div>
    );
}

const ProfileImage = ({ user }) => {
    if (user.plainProfileUrl) {
        return <img className="profile-image" src={user.plainProfileUrl} />
    } else {
        return <div className="profile-image-fallback">{user.nickname.charAt(0)}</div>;
    }
}

const MessageInput = ({ value, onChange, sendMessage, onFileInputChange }) => {
    return (
        <div className="message-input chat-message">
            <input
                placeholder="write a message"
                value={value}
                onChange={onChange}
                onKeyDown={(event => handleEnterPress(event, sendMessage))}
            />
            <div className="message-input-buttons">
                <button className="send-message-button" onClick={sendMessage}>Send Message</button>
                {/* <label className="file-upload-label" htmlFor="upload" >Select File</label>
                <input
                    id="upload"
                    className="file-upload-button"
                    type='file'
                    hidden={true}
                    onChange={onFileInputChange}
                    onClick={() => { }}
                /> */}
            </div>
        </div>
    );
}

const MembersSelect = ({
    applicationUsers,
    groupChannelMembers,
    currentlyJoinedChannel,
    addToChannelMembersList,
    handleCreateChannel,
    handleUpdateChannelMembersList
}) => {
    if (applicationUsers.length > 0) {
        return <div className="overlay">
            <div className="overlay-content">
                <button onClick={() => {
                    if (currentlyJoinedChannel) {
                        handleUpdateChannelMembersList();
                    } else {
                        handleCreateChannel();
                    }
                }}>{currentlyJoinedChannel ? 'Submit' : 'Create'}</button>
                {applicationUsers.map((user) => {
                    const userSelected = groupChannelMembers.some((member) => member === user.userId);
                    return <div
                        key={user.userId}
                        className={`member-item ${userSelected ? 'member-selected' : ''}`}
                        onClick={() => addToChannelMembersList(user.userId)}>
                        <ProfileImage user={user} />
                        <div className="member-item-name">{user.nickname}</div>
                    </div>
                })}
            </div>
        </div>;
    }
    return null;
}

const CreateUserForm = ({
    setupUser,
    settingUpUser,
    userNameInputValue,
    userIdInputValue,
    onUserNameInputChange,
    onUserIdInputChange
}) => {
    if (settingUpUser) {
        return <div className="overlay">
            <div className="overlay-content" onKeyDown={(event) => handleEnterPress(event, setupUser)}>
                <div>User ID</div>
                <input
                    onChange={onUserIdInputChange}
                    className="form-input"
                    type="text" value={userIdInputValue} />

                <div>User Nickname</div>
                <input
                    onChange={onUserNameInputChange}
                    className="form-input"
                    type="text" value={userNameInputValue} />

                <button
                    className="user-submit-button"
                    onClick={setupUser}
                >Connect</button>
            </div>
        </div>
    } else {
        return null;
    }
}

// Helpful functions that call Sendbird
const loadChannels = async (channelHandlers) => {
    const groupChannelFilter = new GroupChannelFilter();
    groupChannelFilter.includeEmpty = true;

    const collection = sb.groupChannel.createGroupChannelCollection({
        filter: groupChannelFilter,
        order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    });

    collection.setGroupChannelCollectionHandler(channelHandlers);

    const channels = await collection.loadMore();
    return [channels, null];
}

const loadMessages = (channel, messageHandlers, onCacheResult, onApiResult) => {
    const messageFilter = new MessageFilter();

    const collection = channel.createMessageCollection({
        filter: messageFilter,
        startingPoint: Date.now(),
        limit: 100
    });

    collection.setMessageCollectionHandler(messageHandlers);
    collection
        .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
        .onCacheResult(onCacheResult)
        .onApiResult(onApiResult);
    return collection;
}

const loadMembers = async (channelUrl, memberListOrder) => {
    try {
        const channel = await sb.groupChannel.getChannel(channelUrl);
        const query = channel.createMemberListQuery({
            limit: 30,
            order: memberListOrder,
        });
        const members = await query.next();
        return [members, null];
    } catch (error) {
        return [null, error];
    }
}



const inviteUsersToChannel = async (channel, userIds) => {
    await channel.inviteWithUserIds(userIds);
}

const createChannel = async (channelName, userIdsToInvite) => {
    try {
        const groupChannelParams = {};
        groupChannelParams.invitedUserIds = userIdsToInvite;
        groupChannelParams.name = channelName;
        groupChannelParams.operatorUserIds = userIdsToInvite;
        const groupChannel = await sb.groupChannel.createChannel(groupChannelParams);
        return [groupChannel, null];
    } catch (error) {
        return [null, error];
    }
}

const deleteChannel = async (channelUrl) => {
    try {
        const channel = await sb.groupChannel.getChannel(channelUrl);
        await channel.delete();
        return [channel, null];
    } catch (error) {
        return [null, error];
    }
}

const deleteMessage = async (currentlyJoinedChannel, messageToDelete) => {
    await currentlyJoinedChannel.deleteMessage(messageToDelete);
}

const getAllApplicationUsers = async () => {
    try {
        const userQuery = sb.createApplicationUserListQuery({ limit: 100 });
        const users = await userQuery.next();
        return [users, null];
    } catch (error) {
        return [null, error];
    }
}

export default ChatScreen;